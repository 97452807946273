import wwPopupWebsiteDomainBuy from './wwPopupWebsiteDomainBuy.vue';
import wwPopupWebsiteDomainConnect from './wwPopupWebsiteDomainConnect.vue';
import wwPopupWebsiteDomainConnectHelp from './wwPopupWebsiteDomainConnectHelp.vue';
import wwPopupWebsiteDomainConnectCAA from './wwPopupWebsiteDomainConnectCAA.vue';
import wwPopupWebsitePublish from './wwPopupWebsitePublish.vue';
import wwPopupWebsitePublishLogs from './wwPopupWebsitePublishLogs.vue';
import wwPopupWebsiteRename from './wwPopupWebsiteRename.vue';
import wwPopupWebsiteCreate from './wwPopupWebsiteCreate.vue';
import wwPopupWebsiteDelete from './wwPopupWebsiteDelete.vue';
import wwPopupWebsiteUnpublish from './wwPopupWebsiteUnpublish.vue';
import wwPopupWebsiteCreateTutorial from './wwPopupWebsiteCreateTutorial.vue';
import wwPopupWebsiteMarketplace from './wwPopupWebsiteMarketplace.vue';
import wwPopupWebsiteSelfHostWewebPreviewURL from './wwPopupWebsiteSelfHostWewebPreviewURL.vue';
import wwPopupWebsiteSelfHostDomain from './wwPopupWebsiteSelfHostDomain.vue';
import wwPopupWebsiteTransfer from './wwPopupWebsiteTransfer.vue';
import wwPopupPlanDowngrade from './wwPopupPlanDowngrade.vue';

export default function install(Vue) {
    Vue.component('wwPopupWebsiteDomainBuy', wwPopupWebsiteDomainBuy);
    Vue.component('wwPopupWebsiteDomainConnect', wwPopupWebsiteDomainConnect);
    Vue.component('wwPopupWebsiteDomainConnectHelp', wwPopupWebsiteDomainConnectHelp);
    Vue.component('wwPopupWebsiteDomainConnectCAA', wwPopupWebsiteDomainConnectCAA);
    Vue.component('wwPopupWebsitePublish', wwPopupWebsitePublish);
    Vue.component('wwPopupWebsitePublishLogs', wwPopupWebsitePublishLogs);
    Vue.component('wwPopupWebsiteRename', wwPopupWebsiteRename);
    Vue.component('wwPopupWebsiteCreate', wwPopupWebsiteCreate);
    Vue.component('wwPopupWebsiteDelete', wwPopupWebsiteDelete);
    Vue.component('wwPopupWebsiteUnpublish', wwPopupWebsiteUnpublish);
    Vue.component('wwPopupWebsiteCreateTutorial', wwPopupWebsiteCreateTutorial);
    Vue.component('wwPopupWebsiteMarketplace', wwPopupWebsiteMarketplace);
    Vue.component('wwPopupWebsiteSelfHostWewebPreviewURL', wwPopupWebsiteSelfHostWewebPreviewURL);
    Vue.component('wwPopupWebsiteSelfHostDomain', wwPopupWebsiteSelfHostDomain);
    Vue.component('wwPopupWebsiteTransfer', wwPopupWebsiteTransfer);
    Vue.component('wwPopupPlanDowngrade', wwPopupPlanDowngrade);
}
