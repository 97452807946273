import services from '@/services';

export default {
    async fetchMaintenance({ commit }) {
        const maintenance = await services.api.info.getMaintenance();
        commit('setMaintenance', maintenance);
    },
    setFullPageLoading({ commit }, fullPageLoading) {
        commit('setFullPageLoading', fullPageLoading);
    },
    /*=============================================m_ÔÔ_m=============================================\
        User
    \================================================================================================*/
    async fetchUser({ commit }) {
        const user = await services.api.user.getMe();
        commit('setUser', { user });
    },
    async logoutUser({ commit }) {
        try {
            await services.api.user.logout();
        } catch (error) {
            wwLib.wwLog.error(error);
        } finally {
            commit('cleanUserData');
            commit('setUser', { user: {} });
        }
    },

    /*=============================================m_ÔÔ_m=============================================\
        Organization
    \================================================================================================*/
    async fetchOrganizations({ commit }) {
        const organizations = await services.api.workspace.getOrganizations();
        commit('setOrganizations', { organizations });
    },
    async fetchOrganization({ commit }, organizationId) {
        commit('setCurrentOrganizationId', { organizationId });
        const organization = await services.api.workspace.getOrganization(organizationId);
        commit('updateOrganization', { organizationId, organization });
    },
    async createOrganization({ commit }, name) {
        const organization = await services.api.workspace.createOrganization(name);
        commit('addOrganization', { organization });
        return organization;
    },
    async deleteOrganization({ commit }, organizationId) {
        await services.api.workspace.deleteOrganization(organizationId);
        commit('deleteOrganization', { organizationId });
    },
    async fetchSelfHostUpdateStatus({ commit }, organizationId) {
        const updateStatus = await services.api.workspace.getSelfHostUpdateStatus(organizationId);
        commit('setSelfHostUpdateStatus', { updateStatus });
    },
    /*=============================================m_ÔÔ_m=============================================\
        Designs
    \================================================================================================*/
    async fetchDesigns({ commit }, organizationId) {
        const designs = await services.api.design.getDesigns(organizationId);
        commit('setDesigns', { organizationId, designs });
    },
    async fetchDesignsMetrics({ commit }, organizationId) {
        const designsMetrics = await services.api.design.getDesignsMetrics(organizationId);
        for (const designMetrics of designsMetrics) {
            commit('setDesignMetrics', { websiteId: designMetrics.designId, designMetrics });
        }
    },
    async fetchDesign({ commit }, { organizationId, designId }) {
        commit('setCurrentDesignId', { designId });
        const design = await services.api.design.getDesign(designId);
        commit('updateDesign', { organizationId, design });
    },
    async updateDesign({ commit }, { organizationId, design }) {
        commit('updateDesign', { organizationId, design });
    },
    async deleteDesign({ commit }, websiteId) {
        await services.api.design.deleteDesign(websiteId);
        commit('deleteDesign', { websiteId });
    },
    async deleteDomainName(_, websiteId) {
        await services.api.design.deleteDomainName(websiteId);
        // commit('deleteDesign', { websiteId });
    },
    async fetchDesignDeploys({ commit }, websiteId) {
        const { data } = await axios.get(
            `${process.env.VUE_APP_API_URL.replace('/v1', '/v2')}/projects/${websiteId}/deploys`
        );
        commit('setDesignDeploys', { websiteId, deploys: data });
    },
    async fetchDesignPublishLogs({ commit }, { websiteId, deployId }) {
        const logs = await services.api.design.getPublishLogs(websiteId, deployId);
        commit('setDesignPublishLogs', { websiteId, deployId, logs });
    },
    async transferDesign({ commit }, { websiteId, organizationId }) {
        await axios.post(
            `${process.env.VUE_APP_API_URL.replace('/v1', '/v2')}/projects/${websiteId}/transfer/${organizationId}`
        );
        //commit('transferDesign', { websiteId, organizationId });
    },

    /*=============================================m_ÔÔ_m=============================================\
        SectionBases
    \================================================================================================*/
    async fetchSectionBases({ commit }, organizationId) {
        const sectionBases = await services.api.sourceCode.getOrganizationSectionBases(organizationId);
        commit('setSectionBases', { organizationId, sectionBases });
    },
    async createSectionBase({ commit }, { organizationId, name, repository }) {
        const result = await services.api.sourceCode.createSectionBase(organizationId, name, repository);
        commit('addSectionBase', { organizationId, sectionBase: result.data });
    },
    async updateSectionBase({ commit }, sectionBase) {
        await services.api.sourceCode.updateSectionBase(sectionBase.id, sectionBase.repository);
        commit('updateSectionBase', { sectionBase });
    },
    async deleteSectionBase({ commit }, sectionBaseId) {
        await services.api.sourceCode.deleteSectionBase(sectionBaseId);
        commit('deleteSectionBase', { sectionBaseId });
    },
    async setSectionBaseActiveVersion({ commit }, { sectionBase, sectionBaseVersion }) {
        await services.api.sourceCode.setSectionBaseActiveVersion(sectionBase.id, sectionBaseVersion.id);
        commit('updateSectionBase', {
            sectionBase: {
                ...sectionBase,
                sectionBaseVersion,
            },
        });
    },
    async setSectionBaseMembers({ commit }, { sectionBase, members }) {
        const previousMembers = sectionBase.members || [];
        const added = members.filter(member => !previousMembers.some(item => item.id === member.id));
        const removed = previousMembers.filter(member => !members.some(item => item.id === member.id));
        await Promise.all([
            ...added.map(member => services.api.sourceCode.addSectionBaseMember(sectionBase.id, member.id)),
            ...removed.map(member => services.api.sourceCode.removeSectionBaseMember(sectionBase.id, member.id)),
        ]);
        commit('updateSectionBase', {
            sectionBase: {
                ...sectionBase,
                members,
            },
        });
    },
    async forceFetchSectionBase({ commit }, sectionBase) {
        await services.api.sourceCode.forceFetchSectionBase(sectionBase.id);
        commit('updateSectionBase', {
            sectionBase: {
                ...sectionBase,
                sectionBaseVersion: {
                    ...sectionBase.sectionBaseVersion,
                    isLatest: false,
                },
            },
        });
    },
    /*=============================================m_ÔÔ_m=============================================\
        WwObjectBases
    \================================================================================================*/
    async fetchWwObjectBases({ commit }, organizationId) {
        const wwObjectBases = await services.api.sourceCode.getOrganizationWwObjectBases(organizationId);
        commit('setWwObjectBases', { organizationId, wwObjectBases });
    },
    async createWwObjectBase({ commit }, { organizationId, name, repository, type }) {
        const result = await services.api.sourceCode.createWwObjectBase(organizationId, name, repository, type);
        commit('addWwObjectBase', { organizationId, wwObjectBase: result.data });
    },
    async updateWwObjectBase({ commit }, wwObjectBase) {
        await services.api.sourceCode.updateWwObjectBase(wwObjectBase.id, wwObjectBase.repository);
        commit('updateWwObjectBase', { wwObjectBase });
    },
    async deleteWwObjectBase({ commit }, wwObjectBaseId) {
        await services.api.sourceCode.deleteWwObjectBase(wwObjectBaseId);
        commit('deleteWwObjectBase', { wwObjectBaseId });
    },
    async setWwObjectBaseActiveVersion({ commit }, { wwObjectBase, wwObjectBaseVersion }) {
        await services.api.sourceCode.setWwObjectBaseActiveVersion(wwObjectBase.id, wwObjectBaseVersion.id);
        commit('updateWwObjectBase', {
            wwObjectBase: {
                ...wwObjectBase,
                wwObjectBaseVersion,
            },
        });
    },
    async setWwObjectBaseMembers({ commit }, { wwObjectBase, members }) {
        const previousMembers = wwObjectBase.members || [];
        const added = members.filter(member => !previousMembers.some(item => item.id === member.id));
        const removed = previousMembers.filter(member => !members.some(item => item.id === member.id)) || [];
        await Promise.all([
            ...added.map(member => services.api.sourceCode.addWwObjectBaseMember(wwObjectBase.id, member.id)),
            ...removed.map(member => services.api.sourceCode.removeWwObjectBaseMember(wwObjectBase.id, member.id)),
        ]);
        commit('updateWwObjectBase', {
            wwObjectBase: {
                ...wwObjectBase,
                members,
            },
        });
    },
    async forceFetchWwObjectBase({ commit }, wwObjectBase) {
        await services.api.sourceCode.forceFetchWwObjectBase(wwObjectBase.id);
        commit('updateWwObjectBase', {
            wwObjectBase: {
                ...wwObjectBase,
                wwObjectBaseVersion: {
                    ...wwObjectBase.wwObjectBaseVersion,
                    isLatest: false,
                },
            },
        });
    },
    /*=============================================m_ÔÔ_m=============================================\
        Plugins
    \================================================================================================*/
    async fetchPlugins({ commit }, organizationId) {
        const plugins = await services.api.sourceCode.getOrganizationPlugins(organizationId);
        commit('setPlugins', { organizationId, plugins });
    },
    async createPlugin({ commit }, { organizationId, name, repository }) {
        const result = await services.api.sourceCode.createPlugin(organizationId, name, repository);
        commit('addPlugin', { organizationId, plugin: result.data });
    },
    async updatePlugin({ commit }, plugin) {
        await services.api.sourceCode.updatePlugin(plugin.id, plugin.repository);
        commit('updatePlugin', { plugin });
    },
    async deletePlugin({ commit }, pluginId) {
        await services.api.sourceCode.deletePlugin(pluginId);
        commit('deletePlugin', { pluginId });
    },
    async setPluginActiveVersion({ commit }, { plugin, pluginVersion }) {
        await services.api.sourceCode.setPluginActiveVersion(plugin.id, pluginVersion.id);
        commit('updatePlugin', {
            plugin: {
                ...plugin,
                pluginVersion,
            },
        });
    },
    async setPluginMembers({ commit }, { plugin, members }) {
        const previousMembers = plugin.members || [];
        const added = members.filter(member => !previousMembers.some(item => item.id === member.id));
        const removed = previousMembers.filter(member => !members.some(item => item.id === member.id));
        await Promise.all([
            ...added.map(member => services.api.sourceCode.addPluginMember(plugin.id, member.id)),
            ...removed.map(member => services.api.sourceCode.removePluginMember(plugin.id, member.id)),
        ]);
        commit('updatePlugin', {
            plugin: {
                ...plugin,
                members,
            },
        });
    },
    async forceFetchPlugin({ commit }, plugin) {
        await services.api.sourceCode.forceFetchPlugin(plugin.id);
        commit('updatePlugin', {
            plugin: {
                ...plugin,
                pluginVersion: {
                    ...plugin.pluginVersion,
                    isLatest: false,
                },
            },
        });
    },
};
