<template>
    <div class="ww-scroll-bar">
        <!-- Search bar -->
        <wwManagerSearchBar
            v-if="!selectedWorkspace"
            class="search"
            placeholder="Search a workspace by UID"
            v-model="search"
        ></wwManagerSearchBar>
        <div v-if="!selectedWorkspace" class="search-result">
            <div v-if="isSearching" class="p-3 text-center text-blue-500">Searching...</div>
            <div v-else-if="workspace" class="workspace-result p-3">
                {{ workspace.name }}
                <button @click="selectWorkspace(workspace)" class="ww-editor-button -primary">Select</button>
            </div>
            <div v-else-if="search && !workspace" class="p-3 text-center text-yellow-500">Workspace not found</div>
            <div v-else class="p-3 text-center label-m">Grant Access To</div>
        </div>

        <!-- Workspaces list -->
        <div v-if="!selectedWorkspace" class="workspaces-list ww-scroll-bar mt-2">
            <div class="workspace" v-for="workspace in availableWorkspaces" :key="workspace.id">
                <div class="flex flex-col">
                    <span class="label-l">{{ workspace.name }}</span>
                    <span class="label-s text-stale-500 mt-1" style="font-style: italic">{{ workspace.id }}</span>
                </div>
                <button @click="selectWorkspace(workspace)" class="ww-editor-button -primary">Select</button>
            </div>
        </div>

        <!-- Confirm panel -->
        <div v-if="selectedWorkspace" class="ww-popup-website-delete ww-scroll-bar">
            <div class="description">
                <div class="tooltip">Unexpected, bad things can happen if you don't read this!</div>
                <p>
                    This action <b>cannot be undone</b>. This will transfer the
                    <b>{{ options.data.design.name }}</b> project to <b>{{ selectedWorkspace.name }}</b
                    >.
                </p>
                <p>This action will automatically:</p>
                <ul>
                    <li><b>Unpublish</b> your project</li>
                    <li><b>Cancel</b> the subscription for this project (if there was one)</li>
                    <li><b>Remove</b> the custom domain (if one was attached)</li>
                </ul>
                <p>
                    Please type <b>{{ selectedWorkspace.name }}</b> to confirm.
                </p>
            </div>
            <label class="website__label caption-s" for="website-name">
                {{ $t('websites.settings.websiteName') }}
                <span class="website__label-required"> {{ $t('utils.required') }}</span>
            </label>
            <input
                type="text"
                class="website__input ww-editor-input -large caption-m"
                name="website-name"
                v-model="confirmInput"
                :placeholder="$t('utils.name')"
            />
            <button @click="cancelTransfer()" class="ww-editor-button -secondary mt-2">Back to list</button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import services from '@/services';
export default {
    name: 'wwPopupWebsiteTransfer',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            search: '',
            workspace: null,
            isSearching: false,
            selectedWorkspace: null,
            confirmInput: '',
        };
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
            currentWorkspace: 'v2/getCurrentOrganization',
            organizations: 'v2/getOrganizations',
        }),
        availableWorkspaces() {
            return this.organizations
                .filter(org => org.id !== this.currentWorkspace.id)
                .map(org => ({ id: org.id, name: org.name }));
        },
        isValid() {
            if (!this.selectedWorkspace?.name) return false;
            if (this.confirmInput != this.selectedWorkspace?.name) return false;
            return true;
        },
    },
    watch: {
        search(value) {
            if (!value) {
                this.workspace = null;
                return;
            }
            this.searchWorkspace(value);
        },
        selectedWorkspace() {
            this.setButtonConfig();
        },
        confirmInput() {
            this.setButtonConfig();
        },
    },
    methods: {
        ...mapActions({
            transferDesign: 'v2/transferDesign',
        }),
        init() {
            this.setButtonConfig();
        },
        async searchWorkspace(workspaceId) {
            this.isSearching = true;
            try {
                this.workspace = await services.api.workspace.getOrganizationInfo(workspaceId);
            } catch (error) {
                this.workspace = null;
            }
            this.isSearching = false;
        },
        async selectWorkspace(workspace) {
            this.search = '';
            this.selectedWorkspace = workspace;
        },
        setButtonConfig() {
            this.options.setButtonState('NEXT', this.isValid ? 'ok' : 'disabled');
            this.options.setButtonText(
                'NEXT',
                this.isValid ? `Definetly transfer to ${this.selectedWorkspace.name}` : 'Transfer'
            );
        },
        cancelTransfer() {
            this.selectedWorkspace = null;
            this.setButtonConfig();
        },
        async transferWebsite() {
            try {
                await this.transferDesign({
                    websiteId: this.options.data.design.id,
                    organizationId: this.selectedWorkspace.id,
                });

                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Project successfully transferred</b> ',
                        fr: '<b>Projet transféré avec succès</b>',
                    },
                    color: 'green',
                    duration: '5000',
                });

                this.$router.replace(`/workspaces/${this.selectedWorkspace.id}`);
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while transferring the project</b>',
                        fr: '<b>Erreur lors du transfert du projet</b>',
                    },
                    color: 'red',
                    duration: '5000',
                });
            }
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            await this.transferWebsite();
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.workspaces-list {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > .workspace {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--ww-spacing-03) var(--ww-spacing-03);
        border-radius: var(--ww-border-radius-02);
        background-color: var(--ww-color-dark-200);
        border: 1px solid var(--ww-color-dark-300);
    }
}
.workspace-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--ww-border-radius-02);
    background-color: var(--ww-color-blue-100);
    border: 1px solid var(--ww-color-dark-300);
}
.search-result {
    min-height: 60px;
    display: flex;
    align-items: center;
    > * {
        width: 100%;
    }
}
.ww-popup-website-delete {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;

    .description {
        .tooltip {
            padding: var(--ww-spacing-02);
            border-radius: var(--ww-spacing-01);
            background-color: var(--ww-color-red-100);
        }
    }
    .website {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__row {
            display: flex;
            align-items: center;
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
            &-radio {
                max-width: 50%;
            }
        }
        &__loader {
            transform: scale(0.8);
        }
        &__error {
            margin: var(--ww-spacing-04) auto;
            color: var(--ww-color-red-500);
        }
        &__lang-selector {
            margin-bottom: var(--ww-spacing-03);
        }
    }
}
</style>
