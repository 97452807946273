<template>
    <div class="ww-popup-website-create ww-scroll-bar">
        <label class="website__label caption-s" for="website-name">
            {{ $t('websites.settings.websiteName') }}
            <span class="website__label-required"> {{ $t('utils.required') }}</span>
        </label>
        <input
            ref="input-name"
            type="text"
            class="website__input ww-editor-input -large caption-m"
            name="website-name"
            v-model="websiteName"
            :placeholder="$t('utils.name')"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import services from '@/services';

export default {
    name: 'wwPopupWebsiteCreate',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            websiteName: '',
        };
    },
    watch: {
        websiteName: {
            immediate: true,
            handler() {
                this.options.setButtonState('NEXT', this.websiteName.length ? 'ok' : 'disabled');
            },
        },
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
            designs: 'v2/getDesigns',
        }),
    },
    mounted() {
        this.$refs['input-name'].focus();
    },
    methods: {
        ...mapActions({
            fetchDesign: 'v2/fetchDesign',
        }),
        async beforeNext() {
            this.options.setLoadingStatus(true);

            try {
                if (!this.websiteName) throw new Error('FAILED_TO_CREATE_WEBSITE');
                const websiteName = this.websiteName.trim();

                await services.api.design.duplicateDesign(
                    this.options.data.workspaceId,
                    this.options.data.designId,
                    websiteName,
                    null
                );

                this.$store.dispatch('v2/fetchDesigns', this.options.data.workspaceId);

            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: { en: '<b>Error while creating the project</b>' },
                    color: 'red',
                });
            }
            this.options.setLoadingStatus(false);
        },
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-website-create {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .website {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__row {
            display: flex;
            align-items: center;
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
            &-radio {
                max-width: 50%;
            }
        }
        &__loader {
            transform: scale(0.8);
        }
        &__error {
            margin: var(--ww-spacing-04) auto;
            color: var(--ww-color-red-500);
        }
    }
}
</style>
