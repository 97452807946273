import Vue from 'vue';

export default {
    setMaintenance(state, maintenance) {
        state.maintenance = maintenance;
    },
    setFullPageLoading(state, fullPageLoading) {
        state.fullPageLoading = fullPageLoading;
    },

    /*=============================================m_ÔÔ_m=============================================\
        General
    \================================================================================================*/
    setPageProgress(state, pageProgress) {
        state.pageProgress = pageProgress;
    },
    cleanUserData(state) {
        state.organizations = {};
        state.designs = {};
        state.collections = {};
        state.sectionBases = {};
        state.plugins = {};
    },
    /*=============================================m_ÔÔ_m=============================================\
        Users
    \================================================================================================*/
    setUser(state, { user }) {
        state.user = user;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Organizations
    \================================================================================================*/
    setOrganizations(state, { organizations }) {
        for (const organization of organizations) {
            if (!state.organizations[organization.id]) {
                Vue.set(state.organizations, organization.id, organization);
            }
        }
    },
    addOrganization(state, { organization }) {
        Vue.set(state.organizations, organization.id, organization);
    },
    updateOrganization(state, { organization }) {
        Vue.set(state.organizations, organization.id, organization);
    },
    deleteOrganization(state, { organizationId }) {
        Vue.delete(state.organizations, organizationId);
    },
    setCurrentOrganizationId(state, { organizationId }) {
        state.currentOrganizationId = organizationId;
    },
    setSelfHostUpdateStatus(state, { updateStatus }) {
        state.selfHostingUpdateStatus = updateStatus || {};
    },

    /*=============================================m_ÔÔ_m=============================================\
        Designs
    \================================================================================================*/
    setDesigns(state, { organizationId, designs }) {
        const designsObj = designs.reduce((designsObj, design) => {
            return { ...designsObj, [design.id]: design };
        }, {});
        Vue.set(state.designs, organizationId, designsObj);
    },
    addDesign(state, { organizationId, design }) {
        Vue.set(state.designs[organizationId], design.id, design);
    },
    updateDesign(state, { organizationId, design }) {
        if (!state.designs[organizationId]) Vue.set(state.designs, organizationId, { [design.id]: design });
        if (!state.designs[organizationId][design.id]) Vue.set(state.designs[organizationId], design.id, design);
        for (const designOrganizationId in state.designs) {
            if (state.designs[designOrganizationId][design.id]) {
                for (const key in design) {
                    Vue.set(state.designs[designOrganizationId][design.id], key, design[key]);
                }
            }
        }
    },
    deleteDesign(state, { websiteId }) {
        for (const organizationId in state.designs) {
            Vue.delete(state.designs[organizationId], websiteId);
        }
    },
    setCurrentDesignId(state, { designId }) {
        state.currentDesignId = designId;
    },
    setDesignDeploys(state, { websiteId, deploys }) {
        for (const designOrganizationId in state.designs) {
            if (state.designs[designOrganizationId][websiteId]) {
                Vue.set(state.designs[designOrganizationId][websiteId], 'deploys', deploys);
            }
        }
    },
    updateDesignDeploy(state, { websiteId, deploy }) {
        for (const designOrganizationId in state.designs) {
            if (
                state.designs[designOrganizationId][websiteId] &&
                state.designs[designOrganizationId][websiteId].deploys
            ) {
                if (deploy.activeStaging) {
                    const staging = state.designs[designOrganizationId][websiteId].deploys.find(
                        elem => elem.activeStaging
                    );
                    if (staging) staging.activeStaging = false;
                }
                if (deploy.activeProd) {
                    const production = state.designs[designOrganizationId][websiteId].deploys.find(
                        elem => elem.activeProd
                    );
                    if (production) production.activeProd = false;
                }

                const deployIdx = state.designs[designOrganizationId][websiteId].deploys.findIndex(
                    elem => elem.id === deploy.id
                );
                if (deployIdx !== -1)
                    Vue.set(state.designs[designOrganizationId][websiteId].deploys, deployIdx, deploy);
                else state.designs[designOrganizationId][websiteId].deploys.unshift(deploy);
            }
        }
    },
    setDesignPublishLogs(state, { websiteId, deployId, logs }) {
        for (const designOrganizationId in state.designs) {
            if (
                state.designs[designOrganizationId][websiteId] &&
                state.designs[designOrganizationId][websiteId].deploys
            ) {
                const deployIdx = state.designs[designOrganizationId][websiteId].deploys.findIndex(
                    elem => elem.id === deployId
                );
                if (deployIdx !== -1)
                    Vue.set(state.designs[designOrganizationId][websiteId].deploys[deployIdx], 'logs', logs);
            }
        }
    },
    setDesignMetrics(state, { websiteId, designMetrics }) {
        for (const designOrganizationId in state.designs) {
            if (state.designs[designOrganizationId][websiteId]) {
                Vue.set(state.designs[designOrganizationId][websiteId], 'designMetrics', designMetrics);
            }
        }
    },
    transferDesign(state, { websiteId, organizationId }) {
        for (const designOrganizationId in state.designs) {
            if (state.designs[designOrganizationId][websiteId]) {
                Vue.set(state.designs[organizationId], websiteId, state.designs[designOrganizationId][websiteId]);
                Vue.delete(state.designs[designOrganizationId], websiteId);
            }
        }
    },

    /*=============================================m_ÔÔ_m=============================================\
        SectionBases
    \================================================================================================*/
    setSectionBases(state, { organizationId, sectionBases }) {
        Vue.set(state.sectionBases, organizationId, sectionBases);
    },
    addSectionBase(state, { organizationId, sectionBase }) {
        state.sectionBases[organizationId].push(sectionBase);
    },
    updateSectionBase(state, { sectionBase }) {
        for (const organizationId in state.sectionBases) {
            const sectionBaseIndex = state.sectionBases[organizationId].findIndex(elem => elem.id === sectionBase.id);
            Vue.set(state.sectionBases[organizationId], sectionBaseIndex, sectionBase);
        }
    },
    updateSectionBaseSourceCodeStatus(state, { sectionBaseId, sourceCodeStatus }) {
        for (const organizationId in state.sectionBases) {
            const sectionBaseIndex = state.sectionBases[organizationId].findIndex(elem => elem.id === sectionBaseId);
            if (sectionBaseIndex !== -1)
                Vue.set(state.sectionBases[organizationId][sectionBaseIndex], 'sourceCodeStatus', sourceCodeStatus);
        }
    },
    deleteSectionBase(state, { sectionBaseId }) {
        for (const organizationId in state.sectionBases) {
            const sectionBaseIndex = state.sectionBases[organizationId].findIndex(elem => elem.id === sectionBaseId);
            if (sectionBaseIndex !== -1) Vue.delete(state.sectionBases[organizationId], sectionBaseIndex);
        }
    },
    /*=============================================m_ÔÔ_m=============================================\
        WwObjectBases
    \================================================================================================*/
    setWwObjectBases(state, { organizationId, wwObjectBases }) {
        Vue.set(state.wwObjectBases, organizationId, wwObjectBases);
    },
    addWwObjectBase(state, { organizationId, wwObjectBase }) {
        state.wwObjectBases[organizationId].push(wwObjectBase);
    },
    updateWwObjectBase(state, { wwObjectBase }) {
        for (const organizationId in state.wwObjectBases) {
            const sectionBaseIndex = state.wwObjectBases[organizationId].findIndex(elem => elem.id === wwObjectBase.id);
            Vue.set(state.wwObjectBases[organizationId], sectionBaseIndex, wwObjectBase);
        }
    },
    updateWwObjectBaseSourceCodeStatus(state, { wwObjectBaseId, sourceCodeStatus }) {
        for (const organizationId in state.wwObjectBases) {
            const sectionBaseIndex = state.wwObjectBases[organizationId].findIndex(elem => elem.id === wwObjectBaseId);
            if (sectionBaseIndex !== -1)
                Vue.set(state.wwObjectBases[organizationId][sectionBaseIndex], 'sourceCodeStatus', sourceCodeStatus);
        }
    },
    deleteWwObjectBase(state, { wwObjectBaseId }) {
        for (const organizationId in state.wwObjectBases) {
            const sectionBaseIndex = state.wwObjectBases[organizationId].findIndex(elem => elem.id === wwObjectBaseId);
            if (sectionBaseIndex !== -1) Vue.delete(state.wwObjectBases[organizationId], sectionBaseIndex);
        }
    },
    /*=============================================m_ÔÔ_m=============================================\
        Plugins
    \================================================================================================*/
    setPlugins(state, { organizationId, plugins }) {
        Vue.set(state.plugins, organizationId, plugins);
    },
    addPlugin(state, { organizationId, plugin }) {
        state.plugins[organizationId].push(plugin);
    },
    updatePlugin(state, { plugin }) {
        for (const organizationId in state.plugins) {
            const sectionBaseIndex = state.plugins[organizationId].findIndex(elem => elem.id === plugin.id);
            Vue.set(state.plugins[organizationId], sectionBaseIndex, plugin);
        }
    },
    updatePluginSourceCodeStatus(state, { pluginId, sourceCodeStatus }) {
        for (const organizationId in state.plugins) {
            const sectionBaseIndex = state.plugins[organizationId].findIndex(elem => elem.id === pluginId);
            if (sectionBaseIndex !== -1)
                Vue.set(state.plugins[organizationId][sectionBaseIndex], 'sourceCodeStatus', sourceCodeStatus);
        }
    },
    deletePlugin(state, { pluginId }) {
        for (const organizationId in state.plugins) {
            const sectionBaseIndex = state.plugins[organizationId].findIndex(elem => elem.id === pluginId);
            if (sectionBaseIndex !== -1) Vue.delete(state.plugins[organizationId], sectionBaseIndex);
        }
    },
};
