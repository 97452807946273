export default {
    getMaintenance: state => state.maintenance,
    /*=============================================m_ÔÔ_m=============================================\
        General
    \================================================================================================*/
    getPageProgress: state => state.pageProgress,
    getFullPageLoading: state => state.fullPageLoading,
    /*=============================================m_ÔÔ_m=============================================\
        Users
    \================================================================================================*/
    getUser: state => state.user,

    /*=============================================m_ÔÔ_m=============================================\
        Organizations
    \================================================================================================*/
    getOrganizations: state => Object.values(state.organizations || {}),
    getOrganization: state => organizationId => state.organizations[organizationId],
    getCurrentOrganization: state => state.organizations[state.currentOrganizationId] || {},
    getCurrentRole: (_state, getters) => getters.getCurrentOrganization.role,
    isOwner: (_state, getters) => getters.getCurrentOrganization.role === 'owner',
    isAdmin: (_state, getters) => getters.isOwner || getters.getCurrentOrganization.role === 'admin',
    isPartnerOrganization: (_state, getters) => getters.getCurrentOrganization.plan?.name === 'Partner',

    /*=============================================m_ÔÔ_m=============================================\
        Designs
    \================================================================================================*/
    getDesigns: state => organizationId => Object.values(state.designs[organizationId] || {}),
    getDesign: state => (organizationId, designId) =>
        state.designs[organizationId] && state.designs[organizationId][designId],
    getCurrentDesign: state =>
        (state.designs[state.currentOrganizationId] &&
            state.designs[state.currentOrganizationId][state.currentDesignId]) ||
        {},
    getDesignDeploys: (state, getters) =>
        (getters.getCurrentDesign.deploys || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),

    /*=============================================m_ÔÔ_m=============================================\
        SectionBases
    \================================================================================================*/
    getSectionBases: state => organizationId => Object.values(state.sectionBases[organizationId] || {}),
    getSectionBase: state => (organizationId, sectionBaseId) =>
        state.sectionBases[organizationId] && state.sectionBases[organizationId][sectionBaseId],

    /*=============================================m_ÔÔ_m=============================================\
        WwObjectBases
    \================================================================================================*/
    getWwObjectBases: state => organizationId => Object.values(state.wwObjectBases[organizationId] || {}),
    getWwObjectBase: state => (organizationId, wwObjectBaseId) =>
        state.wwObjectBases[organizationId] && state.wwObjectBases[organizationId][wwObjectBaseId],

    /*=============================================m_ÔÔ_m=============================================\
        Plugins
    \================================================================================================*/
    getPlugins: state => organizationId => Object.values(state.plugins[organizationId] || {}),
    getPlugin: state => (organizationId, pluginId) =>
        state.plugins[organizationId] && state.plugins[organizationId][pluginId],

    /*=============================================m_ÔÔ_m=============================================\
        SelfHosting
    \================================================================================================*/
    getSelfHostUpdateStatus: state => state.selfHostingUpdateStatus,
};
