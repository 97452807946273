export default {
    en: {
        websites: 'Projects',
        website: 'project',
        myWebsites: 'my websites',
        websiteName: 'websites name',
        websiteOwner: 'websites owner',
        createWebsite: 'Create project',
        defaultLang: 'Default language',
        otherLang: 'Additionnal language',
        search: 'Project name',
        lastUpdate: 'last update',
        ownedBy: 'owned by',
        launchEditor: 'edit project',
        upgrade: 'Upgrade',
        archived: 'Archived',
        trialExpiresIn: 'Trial expires in {days} days',
        trialExpired: 'Trial expired',
        plan: {
            trial: 'Trial',
            basic: 'Basic',
            pro: 'Pro',
            enterprise: 'Enterprise',
            archived: 'Archived',
        },
        intervalPlan: {
            annually: 'annually',
            monthly: 'monthly',
        },
        home: {
            name: 'Home',
            createdOn: 'created on',
            unpublishedModif: 'Preproduction',
            draftSite: 'Preproduction project',
            lastSaves: 'Latest saves',
            page: 'page',
            saved: 'Saved',
            deleted: 'Deleted',
            created: 'Created',
            viewMore: 'View more',
            publishButton: 'PUBLISH',
            publishModif: 'Production',
            productionSite: 'Production project',
            lastPublish: 'Latest publish',
            publishing: 'Publishing',
            creatingBackups: 'Creating Backup',
            fetchingData: 'Fetching data',
            cache: 'Generating cache',
            detailsTemplate: '%{action} by <b>%{user}</b> on %{date}',
            detailsHistoTemplate: '%{action} by <b>%{user}</b> (%{mail}) on %{date}',
            langID: 'en-US',
            publish: 'publish',
            goTo: 'Go to',
            editWebsite: 'Open Editor',
            publishWebsite: 'Publish',
        },
        backups: {
            name: 'Backups',
            listBackupsLabel: 'List of backups and checkpoints',
            listBackupsDescriptions:
                'You can have up to {maxBackups} backups. The oldest backup will be deleted when a new one is generated',
            listCheckpointsDescriptions: 'You can have up to {maxCheckpoints} checkpoints.',
            checkpointButton: 'Checkpoint',
            rollbackButton: 'Roll back',
            rollbackEditorButton: 'Roll back preproduction',
            deleteButton: 'Delete',
            types: {
                online: 'Online',
                backup: 'Backup',
                checkpoint: 'Checkpoint',
            },
            table: {
                id: 'ID',
                type: 'Type',
                date: 'Date',
                createdBy: 'Created by',
                createCheckpoint: 'Create checkpoint',
                rollback: 'Roll back (production & preproduction)',
                delete: 'Delete',
            },
        },
        access: {
            name: 'Access',
            descriptionSentence: 'List of users who have access to this project',
            addUserAccesButton: 'Add user access',
            userName: 'User Name',
            userEmail: 'User Email',
            userRole: 'User Role',
            actions: 'Actions',
            revokeAccessButton: 'Revoke',
            editRoleButton: 'Edit',
            roles: {
                owner: 'Owner',
                admin: 'Admin',
                member: 'Member',
            },
        },
        domains: {
            name: 'Domain',
            descriptionSentence: 'List of domain names linked to your project',
            wwDomainName: 'WeWeb Production domain name',
            actions: 'Actions',
            goToButton: 'Go to',
            addCustomDomain: 'Add custom domain name',
            noCustomDomain: 'No custom domain name.',
            customDomainName: 'Custom domain name',
        },
        ownership: {
            name: 'Ownership & Plan',
            owner: 'Owner',
            ownership: 'Ownership',
            plan: 'Plan',
            archived: 'Archived',
            trialPlan: 'Trial',
            basicPlan: 'Basic',
            proPlan: 'Pro',
            annually: 'Billed annually',
            monthly: 'Billed monthly',
            upgradeButton: 'Upgrade',
            cancelButton: 'Cancel',
            expiresOn: 'Expires on',
            changeWebsiteOwner: 'Give ownership',
        },
        settings: {
            websiteName: 'Name',
            name: 'Settings',
            informations: 'Information',
            rename: 'Rename',
            websiteNameValid: 'Project name valid.',
            dangerZone: {
                name: 'Danger Zone',
                irrevocableAction: 'This action is irrevocable.',
                deleteWebsite: 'It will delete this project.',
                delete: 'Delete',
            },
            dangerZoneTitle: 'Danger zone',
            deleteTitle: 'Delete project',
            deleteDesc: 'This action will permanently delete the project',
            deleteButton: 'DELETE PROJECT',
            deleteDisabled: 'You cannot delete a project while it is being published.',
            unpublishTitle: 'Unpublish project',
            unpublishDesc: 'This action will unpublish the project',
            unpublishButton: 'UNPUBLISH PROJECT',
            unpublishDisabled: 'You cannot unpublish a project while it is being published.',
            transferTitle: 'Transfer ownership',
            transferDesc: 'This action will transfer this project to another workspace',
            transferButton: 'TRANSFER OWNERSHIP',
            transferDisabled: 'You cannot transfer the ownership of a project while it is being published.',
            websiteDeployHook: 'After deploy hook',
            websiteDeployHookPlaceholder: 'https://endpoint.com/after-deploy',
            wewebPreviewURL: 'weweb-server URL',
            wewebPreviewURLPlaceholder: 'IP / Domain / URL',
        },
    },
    fr: {
        websites: 'Projets',
        website: 'Projet',
        myWebsites: 'Mes sites web',
        websiteName: 'Nom du projet',
        websiteOwner: 'Propriétaire du projet',
        createWebsite: 'Créer un projet',
        defaultLang: 'Langue par défaut',
        otherLang: 'Langue aditionnelle',
        search: 'Nom du projet',
        lastUpdate: 'Dernière modification',
        ownedBy: 'Propriétaire',
        launchEditor: 'Editer le projet',
        upgrade: 'Modifier le forfait',
        archived: 'Archiver',
        trialExpiresIn: "Période d'essai : {days} jours restant",
        trialExpired: "Période d'essai terminée",
        plan: {
            trial: 'Essai',
            basic: 'Basique',
            pro: 'Pro',
            enterprise: 'Entreprise',
            archived: 'Archivé',
        },
        intervalPlan: {
            annually: 'annuellement',
            monthly: 'mensuellement',
        },
        home: {
            name: 'Accueil',
            createdOn: 'créé le',
            ownedBy: 'propriétaire',
            unpublishedModif: 'Préproduction',
            draftSite: 'Projet en préproduction',
            lastSaves: 'Dernières sauvegardes',
            page: 'page',
            saved: 'sauvegardé',
            deleted: 'supprimé',
            created: 'créé',
            viewMore: 'Voir plus',
            publishButton: 'PUBLIER',
            publishModif: 'Production',
            productionSite: 'Projet en production',
            lastPublish: 'Dernières publications',
            publishing: 'Publication',
            creatingBackups: "Création d'une sauvegarde",
            fetchingData: 'Récupération des données',
            cache: 'Génération du cache',
            detailsTemplate: '%{action} par <b>%{user}</b> le %{date}',
            detailsHistoTemplate: '%{action} par <b>%{user}</b> (%{mail}) le %{date}',
            langID: 'fr-FR',
            publish: 'publier',
            publishWebsite: 'Publier',
            editWebsite: "Ouvrir l'éditeur",
            goTo: 'Voir le',
        },
        backups: {
            name: 'Sauvegardes',
            listBackupsLabel: 'Liste des sauvegardes et des points de contrôle',
            listBackupsDescriptions:
                "Vous pouvez avoir jusqu'à {maxBackups} sauvegardes. Les anciennes sauvegardes seront supprimées au fur et à mesure de la création des nouvelles",
            listCheckpointsDescriptions: "Vous pouvez avoir jusqu'à {maxCheckpoints} points de contrôle.",
            checkpointButton: 'Point de contrôle',
            rollbackButton: 'Retour en arrière',
            rollbackEditorButton: 'Retour en arrière de la préproduction',
            deleteButton: 'Supprimer',
            types: {
                online: 'En ligne',
                backup: 'Sauvegarde',
                checkpoint: 'Point de contrôle',
            },
            table: {
                id: 'ID',
                type: 'Type',
                date: 'Date',
                createdBy: 'Créé par',
                createCheckpoint: 'Créer un point de contrôle',
                rollback: 'Retour en arrière (Prod et préprod)',
                delete: 'Supprimer',
            },
        },
        access: {
            name: 'Accès',
            descriptionSentence: 'Liste des utilisateurs ayant accès à ce projet',
            addUserAccesButton: 'Ajouter un accès utilisateur',
            userName: "Nom de l'utilisateur",
            userEmail: 'Email',
            userRole: 'Role',
            actions: 'Actions',
            revokeAccessButton: 'supprimer',
            editRoleButton: 'Éditer',
            roles: {
                owner: 'Propriétaire',
                admin: 'Administrateur',
                member: 'Membre',
            },
        },
        domains: {
            name: 'Domaines',
            descriptionSentence: 'Liste des noms de domaine menant à votre projet',
            wwDomainName: 'Nom de domaine WeWeb',
            actions: 'Actions',
            goToButton: 'Accéder',
            addCustomDomain: 'Ajouter un nom de domaine personnalisé',
            noCustomDomain: 'Aucun nom de domaine personnalisé.',
            customDomainName: 'Noms de domaine personnalisé',
        },
        ownership: {
            name: 'Propriété et forfait',
            owner: 'Propriétaire',
            ownership: 'Propriété',
            plan: 'Forfait',
            archived: 'Archivé',
            annually: 'Facturé annuellement',
            monthly: 'Facturé mensuellement',
            upgradeButton: 'Améliorer',
            cancelButton: 'Résilier',
            expiresOn: 'Expire le',
            changeWebsiteOwner: 'Transférer la propriété',
        },
        settings: {
            websiteName: 'Nom',
            name: 'Paramètres',
            informations: 'Information',
            rename: 'Renommer',
            websiteNameValid: 'Nom du projet valide.',
            dangerZone: {
                name: 'Zone de danger',
                irrevocableAction: 'Cette action est irrévocable.',
                deleteWebsite: 'Cela supprimera ce projet.',
                delete: 'Supprimer',
            },
            dangerZoneTitle: 'Danger zone',
            transferTitle: 'Transférer la propriété',
            transferDesc: 'Cette action transférera ce projet dans un autre espace.',
            transferButton: 'TRANSFÉRER LA PROPRIÉTÉ',
            transferDisabled: 'Vous ne pouvez pas transférer la propriété d’un projet pendant une publication.',
            deleteTitle: 'Supprimer le projet',
            deleteDesc: 'Cette action supprimera définitivement le projet',
            deleteButton: 'SUPPRIMER LE PROJET',
            deleteDisabled: 'Vous ne pouvez pas supprimer un projet pendant une publication.',
        },
    },
};
